<template>
    <el-upload :class="Isbool? 'oqcuploaddise': ''" :action="`${$Upload}/api/OQC/Imgaupd/`" list-type="picture-card" :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove" :data="filedata" :file-list="fileList" :on-success="handsuccess" :disabled="Isbool">
        <i class="el-icon-plus"></i>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </el-upload>
</template>
<script>
export default {
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      filedata: {
        Numbers: this.Numbers,
        Type: this.Type,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username
      }
    }
  },
  props: ['Numbers', 'Type', 'Isbool'], // 接手psMsg值
  created () {
    this.GetAllFiles(this.Numbers, this.Type)
  },
  mounted () {
  },
  methods: {
    handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = this.$Upload + '/' + response.url
      this.fileList.push(data)
    },
    async handleRemove (file) {
      try {
        const delfile = {
          Numbers: this.Numbers,
          Type: this.Type,
          User: this.$store.state.Login.User,
          Username: this.$store.state.Login.Username,
          Wname: file.name
        }
        const { data: res } = await this.$http.post('/api/OQC/ImgDel', delfile)
        if (res.status !== 200) {
          return this.$message.error(this.$t('All.数据异常'))
        }
        for (let index = 0; index < this.fileList.length; index++) {
          if (this.fileList[index].name === file.name) {
            this.fileList.splice(index, 1)
            return this.$message.success(this.$t('All.删除成功'))
          }
        }
        this.$message.success(this.$t('All.删除成功'))
      } catch (error) {
        this.$message.error(error)
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async GetAllFiles (row, type) {
      try {
        const { data: res } = await this.$http.post(`/api/OQC/GetAllFiles?Numbers=${row}&Type=${type}`)
        if (res.status === 201) return
        if (res.status !== 200) return this.$message.error(this.$t('All.数据异常'))
        const fileListWithUrl = res.response.map(file => {
          return {
            ...file,
            url: this.$Upload + '/' + file.url
          }
        })
        this.fileList = fileListWithUrl
        console.log()
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>
